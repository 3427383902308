.wrapper {
  margin: 0.4rem;
}

.skillHeader {
  font-family: "Bebas Neue", cursive;
  font-size: 1.563rem;
  margin-top: 1.2rem;
  letter-spacing: 0.15rem;
  text-decoration: underline;
}

.text {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.link {
  color: #2c8c99;
  text-decoration: none;
  transition: linear 0.7s;
}

.link:hover {
  color: rgb(248, 248, 248);
  transition: linear 0.3s;
}

.list {
  font-size: 1.25rem;
  margin-left: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    margin-top: 0.4rem;
  }
}
