.app {
  text-align: center;
  display: grid;
  grid-template-columns: 10rem 1fr;
  background-color: #1d1d1d;
}

.info {
  position: relative;
  align-self: stretch;
  display: flex;
}

@media (max-width: 800px) {
  .app {
    display: flex;
    align-items: stretch;
    min-height: 94.8vh;
    flex-direction: column;
  }

  .info {
    justify-content: center;
    align-items: stretch;
    min-height: 94.8vh;
  }
}
