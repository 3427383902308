.wrapper {
  position: absolute;
  z-index: 1;
  right: 1rem;
  top: 2rem;
  display: flex;
  align-items: center;
}

.icon {
  color: #2c8c99;
  font-size: 1.1rem;
}

.link {
  margin-top: 0;
  color: #2c8c99;
  font-weight: bold;
  text-decoration: none;
  transition: linear 0.3s;
}

.link:hover {
  color: rgb(212, 212, 212);
}

@media (max-width: 800px) {
  .wrapper {
    top: 0rem;
  }

  .icon {
    font-size: 1rem;
  }
}
