.wrapper {
  background: #ff0000;
  background: -webkit-linear-gradient(top left, #202020 0%, #3a3a3a 100%);
  background: -moz-linear-gradient(top left, #202020 0%, #3a3a3a 100%);
  background: -o-linear-gradient(top left, #202020 0%, #3a3a3a 100%);
  background: linear-gradient(to bottom right, #202020 0%, #3a3a3a 100%);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: 0;
}

.bgBubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 0.5;

  li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    bottom: -180px;
    color: #2c8c993a;
    backdrop-filter: blur(5px);
    background-color: #ffffff09;
    border: none;

    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;

    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;

    &:nth-child(1) {
      left: 10%;
    }

    &:nth-child(2) {
      left: 20%;

      width: 80px;
      height: 80px;

      animation-delay: 2s;
      animation-duration: 55s;
    }

    &:nth-child(3) {
      left: 25%;
      animation-delay: 4s;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;

      animation-duration: 45s;

      background-color: fade(white, 25%);
    }

    &:nth-child(5) {
      left: 70%;
    }

    &:nth-child(6) {
      left: 80%;
      width: 120px;
      height: 120px;

      animation-delay: 3s;
      background-color: fade(white, 20%);
    }

    &:nth-child(7) {
      left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 55%;
      width: 20px;
      height: 20px;

      animation-delay: 15s;
      animation-duration: 50s;
    }

    &:nth-child(9) {
      left: 25%;
      width: 10px;
      height: 10px;

      animation-delay: 2s;
      animation-duration: 50s;
      background-color: fade(white, 30%);
    }

    &:nth-child(10) {
      left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 11s;
    }
    &:nth-child(11) {
      left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 22s;
    }
    &:nth-child(12) {
      left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 26s;
    }
  }
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100rem) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100rem) rotate(600deg);
  }
}
