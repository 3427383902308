.wrapper {
  border-radius: 0.5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 6.5rem;
  height: 6.5rem;
}

.header {
  margin-top: 1rem;
  font-size: 1.563rem;
  text-align: center;
}
