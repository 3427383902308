.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 620px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
