.nav {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1b1b1b;
  // border-right: 1px solid #2a2a2a;
  justify-content: space-between;
  transition: all 300ms ease-out;
  li {
    padding: 0.4rem;
    border-top: 1px solid #2a2a2a;
    border-right: none;
    font-size: 1.563rem;
    letter-spacing: 0.15rem;
    font-family: "Bebas Neue", cursive;
  }
  li:last-child {
    border-bottom: 1px solid #2a2a2a;
  }
}

.logo {
  width: 90%;
  margin-top: 0.5rem;
}

.notActive {
  color: rgb(248, 248, 248);
  text-decoration: none;
  transition: linear 0.7s;
}

.notActive:hover {
  color: #2c8c99;
  transition: linear 0.3s;
}

.active {
  color: #2c8c99;
  text-decoration: none;
}

.social {
  display: flex;
  justify-content: center;
  height: 10rem;
}

.smIcon {
  font-size: 2.5rem;
  color: rgb(248, 248, 248);
  padding: 0.2rem;
  transition: all 0.3s;
}

.smIcon:hover {
  color: #2c8c99;
  transition: all 0.3s;
}

@media (max-width: 800px) {
  .nav {
    height: 5rem;
    flex-direction: row;
    align-items: center;
    li {
      border: none;
      border-left: 1px solid #2a2a2a;
      border-right: 1px solid #2a2a2a;
      font-size: 1.25rem;
    }
    li:last-child {
      border-bottom: none;
    }
  }

  .logo {
    margin-top: 0rem;
    margin-left: 0.4rem;
    height: 5rem;
    transition: 0.3s;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .social {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 0.6rem;
  }
}

@media (max-width: 445px) {
  .logo {
    height: 3rem;
    transition: 0.3s;
  }
}

@media (max-width: 400px) {
  li {
  }
}
