.wrapper {
  position: relative;
  z-index: 1;
  align-self: center;
  justify-self: start;
  text-align: left;
  margin: 2rem;
  max-width: 1000px;
  width: 90%;
  animation-name: fade;
  animation-duration: 700ms;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .wrapper {
    align-self: flex-start;
    max-width: 100%;
  }
}
