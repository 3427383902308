.heroText {
  max-width: 60%;
  margin: 2rem;
  align-self: center;
  justify-self: start;
  text-align: left;
  color: rgb(248, 248, 248);
  padding-bottom: 5rem;

  p {
    line-height: 160%;
  }
}

.cascadingText {
  &__letter {
    opacity: 0;
    animation-name: fade;
    max-width: 72ch;
    margin-bottom: 0.8rem;
    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}) {
        animation-delay: 200ms * $i;
      }
    }
    animation-duration: 700ms;
    animation-fill-mode: forwards;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .heroText {
    align-self: flex-start;
    max-width: 80%;
  }
}
