.wrapper {
  margin-top: 1.5rem;
}

.container {
  display: flex;
  border: 2px solid #2c8c99;
  width: 20rem;
  transition: box-shadow 0.5s;
  backdrop-filter: blur(4px);

  &:hover,
  &:focus {
    border-color: #2c8c99;
    color: #fff;
    box-shadow: inset 20rem 0 0 0.09px #2c8c99;
  }
}

.icon {
  cursor: pointer;
  flex: 0;
  padding: 1rem;
  border-right: 0.1px solid #2c8c99;
}

.button {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  font-family: inherit;
  cursor: pointer;
  color: #e1e2e1;
  background: none;
  flex: 1;
  border: none;
  padding: 0;
}

.floatingText {
  opacity: 0%;
  color: #2c8c99;
  font-size: 1.563rem;
  font-weight: 500;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0.2rem;
  width: 21rem;
}

.fadeAnim {
  animation-name: fade;
  animation-duration: 2s;
}

@keyframes fade {
  0% {
    opacity: 0%;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0%;
    transform: translateY(-40px);
  }
}
