h1 {
  line-height: 3.5rem;
}

h4 {
  padding: 2rem 0 0 0;
  color: rgb(216, 215, 215);
  max-width: 70ch;
}

.heroText {
  max-width: 60%;
  margin: 2rem;
  align-self: center;
  justify-self: start;
  text-align: left;
  color: rgb(248, 248, 248);
  padding-bottom: 5rem;
  position: relative;
  z-index: 1;
  animation-name: fade;
  animation-duration: 700ms;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .heroText {
    align-self: flex-start;
    margin-right: 10rem;
  }
}
