$wordLength: 3;
$animationDuration: 0.9s;
$animationInterval: 0.1s;

.container {
  max-width: 90%;
  margin: 2rem;
  align-self: center;
  justify-self: start;
  text-align: left;
  color: rgb(248, 248, 248);
  position: relative;
  z-index: 1;
}

.linktop {
  display: inline-block;
  color: #2c8c99;
  font-weight: bold;
  text-decoration: none;
}

.linktop:hover {
  color: white;
  transition: 0.5s;
}

.link {
  padding-top: 1rem;
  display: inline-block;
  color: #2c8c99;
  font-weight: bold;
  text-decoration: none;
}

.linkSection {
  display: flex;
  justify-content: space-between;
}

.header {
  display: flex;
}

.link:hover {
  color: white;
  transition: 0.5s;
}

.cascadingText {
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: row;
  &__letter {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 33%;
    border-radius: 0.5rem;
    // background: #272727;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.534);
    margin-right: 1.5rem;
    margin-top: 1rem;
    opacity: 0;
    animation-name: fade;
    @for $i from 1 through $wordLength {
      &:nth-of-type(#{$i}) {
        animation-delay: $animationInterval * $i;
      }
    }
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
  }
}

.img {
  width: 100%;
  height: 15rem;
  object-fit: cover;
  object-position: 20% 10%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  filter: grayscale(100%);
  transition: 1.5s;
}

.textWrapper {
  height: 100%;
}

.cardText {
  h2 {
    color: white;
  }
  h4 {
    max-width: 72ch;
  }

  margin: 1rem;
}

.tint {
  position: relative;
  overflow: hidden;
}

.tint::after {
  transition: 1.5s;
  position: absolute;
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: rgba(44, 140, 153, 0.253);
  top: 0;
  left: 0;
  display: block;
  content: "";
}

.tint:hover::after {
  transition: 0.5s;
  background-color: rgba(44, 140, 153, 0);
}

.tint:hover .img {
  transition: 0.5s;
  filter: grayscale(0%);
  transform: scale(1.05);
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.portfolio {
  margin-top: 1rem;
  padding: 0rem;
}

@media (max-width: 800px) {
  .container {
    align-self: flex-start;
    margin: 1rem;
  }
  .header {
    justify-content: center;
  }

  .cardText {
    h4 {
      height: auto;
    }
  }

  .cascadingText {
    flex-direction: column;
    align-items: center;
    &__letter {
      width: 100%;
      margin-right: 0rem;
    }
    &__letter:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .social {
    padding: initial;
    padding-right: 0.2rem;
  }
}
